import React from 'react';

import type { FlagProps } from '../src/types';

const FlagYt = ({ className, testID }: FlagProps) => (
  <svg
    width={20}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testID}
    className={className}
    aria-hidden="true"
  >
    <path fill="#fff" d="M0 0h20v15H0z" />
    <path
      d="M6.931 13.268c-.416.126-1.015.384-1.255.528-.078.05-.07.107.05.321.084.138.169.258.183.258.02 0 .254-.094.515-.214.26-.113.594-.262.747-.29.117-.02.225-.039.29-.064.027.058.011.026.06.117.07.13.026.127.147.163.127.037.441-.114.441-.114.367-.114.572-.126 1.89-.126 1.32 0 1.524.012 1.891.132 0 0 .295.09.333.1.038.01.058.02.098.01.036-.007.074-.056.09-.086 0 0 .082-.174.103-.235.07.03.198.07.322.104.148.044.48.176.74.29.261.119.494.213.515.213.014 0 .099-.12.184-.258.169-.296.183-.27-.43-.553-.819-.378-1.637-.547-1.715-.365-.014.034-.026.055-.045.1-.082-.017-.297-.062-.512-.094-.663-.107-2.483-.107-3.146 0-.215.032-.36.084-.482.114-.07-.123-.055-.074-.069-.108-.056-.126-.43-.106-.945.057z"
      fill="#DFDFDF"
    />
    <path
      d="M7.53 5.496c-.013.205.03.35.14.46.05.052.093.114.093.114s-.057.063-.104.11a.547.547 0 0 0-.157.403c0 .155.045.262.166.402 0 0 .102.052.1.115-.003.064-.11.128-.11.128-.217.255-.21.547.017.79l.098.107h4.46l.109-.122c.21-.238.21-.495.002-.768 0 0-.105-.075-.107-.137-.001-.063.1-.125.1-.125.218-.275.213-.528-.012-.793-.102-.117-.104-.14-.024-.194.13-.093.211-.405.152-.595-.088-.285-.464-.475-.694-.35a1.533 1.533 0 0 0-.192.137.586.586 0 0 1-.135.098.636.636 0 0 1-.126-.1c-.23-.208-.506-.233-.736.002 0 0-.032.126-.08.13-.049.004-.117-.112-.117-.112-.234-.233-.528-.223-.767.022 0 0-.034.103-.088.09-.054-.012-.098-.132-.098-.132-.14-.135-.229-.133-.39-.135-.092-.002-.26.02-.362.097-.101.075-.083.166-.107.16-.058-.015-.123-.11-.123-.11-.227-.205-.41-.24-.63-.117a.499.499 0 0 0-.277.425z"
      fill="#171796"
    />
    <path
      d="M7.678 9.057s.09.045.088.101c-.003.057-.1.113-.1.113a.932.932 0 0 0-.133.195.57.57 0 0 0 .13.588c.09.09.093.128.008.236a.972.972 0 0 0-.1.148.55.55 0 0 0 .481.787.478.478 0 0 0 .382-.17c.085-.093.13-.096.206-.012.111.128.258.207.381.207.145 0 .235-.047.382-.192 0 0 .058-.114.111-.114.053.001.102.116.102.116.22.246.443.253.74.02 0 0 .065-.095.12-.094.056.002.104.099.104.099.13.118.228.164.356.164.128 0 .223-.051.372-.204 0 0 .077-.115.123-.123.046-.007.095.089.095.089.182.17.282.224.415.224.137 0 .234-.047.371-.175.129-.12.197-.26.18-.376a1 1 0 0 0-.206-.433s-.08-.007-.068-.07c.012-.061.106-.125.106-.125.233-.283.235-.502.005-.782 0 0-.096-.055-.095-.118.002-.063.1-.126.1-.126.223-.273.22-.526-.005-.775l-.12-.13-2.218.002-2.218.003-.1.116c-.232.268-.23.54.005.811z"
      fill="#D42E12"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.138 8.514c.107 0 .197.073.215.17l.004.036v.385l.46-.245a.21.21 0 0 1 .283.085.205.205 0 0 1-.054.26l-.032.02-.385.204.385.205.032.02c.077.061.102.17.054.26a.21.21 0 0 1-.284.084l-.46-.244v.428a.213.213 0 0 1-.218.207.216.216 0 0 1-.215-.17l-.004-.037v-.376l-.361.192a.21.21 0 0 1-.284-.084.205.205 0 0 1 .054-.26l.032-.02.385-.205-.385-.204-.032-.02a.205.205 0 0 1-.054-.26.21.21 0 0 1 .284-.085l.361.192V8.72c0-.114.098-.206.219-.206zm-2.125 0c.108 0 .197.073.216.17l.003.036v.385l.46-.245a.21.21 0 0 1 .283.085.205.205 0 0 1-.054.26l-.031.02-.386.204.386.205.031.02c.078.061.103.17.054.26a.21.21 0 0 1-.283.084l-.46-.244v.428a.213.213 0 0 1-.219.207.216.216 0 0 1-.215-.17l-.003-.037v-.376l-.362.192a.21.21 0 0 1-.283-.084.205.205 0 0 1 .054-.26l.031-.02.386-.205-.386-.204-.031-.02a.205.205 0 0 1-.054-.26.21.21 0 0 1 .283-.085l.362.192V8.72c0-.114.098-.206.218-.206z"
      fill="#E9D315"
    />
    <path
      d="M6.677 4.375v3.66h.2v.09c-.114-.004-.198-.088-.2-.09v1.428c.002.758.003 1.614.005 2.006a.353.353 0 0 0 .09.262.57.57 0 0 0 .078.07c.012.009.02.016.03.022l.034.026.124-.156-.124.156c.075.06.19.1.302.128.121.031.272.058.443.079.34.04.77.055 1.228.02a.902.902 0 0 1 .388.08.73.73 0 0 1 .222.17c.032.033.06.068.09.104l.003.004c.027.034.06.073.092.107a.45.45 0 0 0 .33.159c.156 0 .26-.088.328-.161a1.75 1.75 0 0 0 .092-.112l.002-.002c.028-.037.054-.07.083-.104a.68.68 0 0 1 .207-.167.82.82 0 0 1 .372-.079 12.1 12.1 0 0 0 1.285-.036 3.16 3.16 0 0 0 .425-.063.713.713 0 0 0 .295-.124l.004-.004a.742.742 0 0 0 .068-.063.529.529 0 0 0 .123-.187.57.57 0 0 0 .027-.244V4.175H6.677v.2z"
      stroke="#000"
      strokeWidth={0.4}
    />
    <path
      d="M9.293 6.166c-.168.312-.175.453-.035.703a.862.862 0 0 0 .41.392c.457.207 1.04.054 1.27-.33.141-.236.125-.63-.031-.803l-.043-.046-.04.136c-.057.194-.113.293-.227.397-.212.194-.505.238-.808.118-.234-.094-.328-.225-.416-.587l-.021-.09-.06.11z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.13 2c0 .577-.356 1-.857 1-.5 0-.854-.423-.854-1 0-.58.353-1 .854-1s.858.42.858 1zm-6.83.968V1.621l.51 1.347h.223l.499-1.347v1.347h.301V1.032h-.35L4.924 2.52l-.565-1.49H4v1.937h.299zm2.116 0 .19-.536h.71l.19.536h.32l-.713-1.936h-.301l-.713 1.936h.317zm.29-.82h.507l-.254-.716-.253.716zm2.01.82v-.772l.674-1.164h-.332l-.49.867-.492-.867h-.34l.67 1.164v.772h.31zm1.558-.261c-.326 0-.537-.297-.537-.707s.211-.707.537-.707c.326 0 .54.297.54.707s-.214.707-.54.707zm1.878-1.385v1.646h-.314V1.322h-.616v-.29h1.546v.29h-.616zm1.727 1.646V1.322h.616v-.29h-1.546v.29h.616v1.646h.314zM16 2.675v.293h-1.21V1.032H16v.29h-.903v.51h.819v.288h-.819v.555H16z"
      fill="#FF2500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.11 11.75s-.212-.418.325-.625c.538-.207.8.063 1.037.313.237.25.21 1.005.21 1.005l-.584.936-1.795.371-.715-.371-.838-1.629.063-1.6s.084-.612.084-.738c0-.127.39-1.11.39-1.11l.369-.365 1.043-.555.491-.246.61-.77-.068-.614-.695.885h-.588l-.613.257-.248.233-.301.01-.118-.243.329-.529.497-.5-.229-.423.339-.154-.065-.234.375.234.284-.234.054-.214-.648-.348s.634-.266.932-.238c.299.028.58.38.58.38l.855-.142s-.229.562-.155.562h.538l-.172.698h.662l-.49.537.382.21-.58.395.58.39-.54.356.304.588.48-.291.269.365-.405.9-.662.47-.63.158-.618.16-.245.16-.153-.615-1.042.715-.541 1.121.159 1.019.382.521.704.252.918-.172.48-.585.115-.597s-.278-.404-.48-.37c-.201.033-.622.311-.622.311z"
      fill="#DFDFDF"
      stroke="#000"
      strokeWidth={0.3}
    />
    <path
      d="M15.054 7.762s1.112.103 1.409-.133m-2.245.854s1.111.104 1.408-.132m-1.596.795s.6.1.66-.108"
      stroke="#000"
      strokeWidth={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.469 5.938a.156.156 0 1 1 0-.313.156.156 0 0 1 0 .313z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.89 11.75s.212-.418-.325-.625c-.538-.207-.8.063-1.037.313-.237.25-.21 1.005-.21 1.005l.584.936 1.795.371.715-.371.838-1.629-.063-1.6s-.084-.612-.084-.738c0-.127-.39-1.11-.39-1.11l-.369-.365-1.043-.555-.491-.246-.61-.77.068-.614.695.885h.588l.613.257.248.233.301.01.118-.243-.329-.529-.497-.5.229-.423-.339-.154.065-.234-.375.234-.284-.234-.054-.214.648-.348s-.634-.266-.932-.238c-.299.028-.58.38-.58.38l-.855-.142s.229.562.155.562h-.538l.172.698h-.662l.49.537-.382.21.58.395-.58.39.54.356-.304.588-.48-.291-.269.365.405.9.662.47.63.158.618.16.245.16.153-.615 1.042.715.542 1.121-.16 1.019-.382.521-.704.252-.918-.172-.48-.585-.116-.597s.279-.404.48-.37c.203.033.623.311.623.311z"
      fill="#DFDFDF"
      stroke="#000"
      strokeWidth={0.3}
    />
    <path
      d="M4.946 7.762s-1.112.103-1.409-.133m2.245.854s-1.111.104-1.408-.132m1.596.795s-.6.1-.66-.108"
      stroke="#000"
      strokeWidth={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.531 5.938a.156.156 0 1 0 0-.313.156.156 0 0 0 0 .313z"
      fill="#000"
    />
  </svg>
);

export default FlagYt;
