const primitives = {
  // brand
  brandSleep: '#F4F6FC',
  brandThink: '#372680',
  brandGo: '#0B857D',
  // gray
  gray1000: '#FFFFFF',
  gray900: '#F7F7F7',
  gray800: '#E8E8E8',
  gray700: '#D9D9D9',
  gray600: '#CCCCCC',
  gray500: '#ADADAD',
  gray400: '#949494',
  gray300: '#787878',
  gray200: '#616161',
  gray150: '#525252',
  gray100: '#404040',
  gray50: '#212121',
  gray0: '#161616',
  // blue
  blue800: '#E7f0FC',
  blue700: '#CEE2F9',
  blue600: '#B6DDFF',
  blue500: '#6DA7EE',
  blue400: '#3D8AE8',
  blue300: '#0C6DE2',
  blue200: '#0A57B5',
  blue100: '#074188',
  blue50: '#052C5A',
  // red
  red800: '#FFEAE7',
  red700: '#F2D0CE',
  red600: '#E5A19D',
  red500: '#D9726C',
  red400: '#CC433B',
  red300: '#BF140A',
  red200: '#991008',
  red100: '#660B05',
  red50: '#4C0804',
  // yellow
  yellow800: '#FFF8EB',
  yellow700: '#FFF2D9',
  yellow600: '#FEE5B5',
  yellow500: '#FFD582',
  yellow400: '#FFC759',
  yellow300: '#FFA617',
  yellow200: '#F28900',
  yellow100: '#BA5D00',
  yellow50: '#964905',
  // green
  green800: '#EBFFF1',
  green700: '#CEE6DE',
  green600: '#9ECEBE',
  green500: '#6DB59D',
  green400: '#3D9D7D',
  green300: '#0C845C',
  green200: '#0A6A4A',
  green100: '#074F37',
  green50: '#053525',
  // purple
  purple800: '#F5F2FC',
  purple700: '#ebe7fb',
  purple600: '#c3b6f3',
  purple500: '#9b85eb',
  purple400: '#7255e3',
  purple300: '#4a24db',
  purple200: '#3a1caa',
  purple100: '#29147a',
  purple50: '#231163',
  // static
  staticSleep: '#0e1438',
  staticThink: '#CBC1F6',
  staticGo: '#76fb8f',
  staticBlack: '#000000',
  staticDimGray: '#444444',
  staticDarkGray: '#707070',
  staticLightGray: '#C6C6C6',
  staticWhite: '#FFFFFF',
} as const;

export default primitives;
