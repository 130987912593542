import React from 'react';

import type { FlagProps } from '../src/types';

const FlagMh = ({ className, testID }: FlagProps) => (
  <svg
    width={20}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testID}
    className={className}
    aria-hidden="true"
  >
    <mask
      id="prefix__a"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={15}
    >
      <path fill="#fff" d="M0 0h20v15H0z" />
    </mask>
    <g mask="url(#prefix__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0v15h20V0H0z"
        fill="#3D58DB"
      />
      <mask
        id="prefix__b"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={20}
        height={15}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0v15h20V0H0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__b)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m5 6.875-.51 1.84.004-1.91-.988 1.635.52-1.838-1.393 1.307.997-1.63-1.694.884 1.4-1.3-1.87.393 1.698-.875-1.905-.125 1.87-.384-1.8-.635 1.904.135L1.67 3.275l1.798.644L2.26 2.44l1.558 1.106-.765-1.75 1.2 1.484-.265-1.89.757 1.752L5 1.25l.255 1.892.757-1.753-.265 1.891 1.201-1.484-.765 1.75L7.741 2.44 6.53 3.92l1.799-.644-1.563 1.097 1.905-.135-1.801.635 1.87.384-1.905.125 1.697.875-1.868-.393 1.399 1.3L6.37 6.28l.997 1.629-1.393-1.307.52 1.838-.988-1.635.005 1.91L5 6.875z"
          fill="#F7FCFF"
        />
        <path d="M-.489 15.466 20.786.759v4.795L-.489 15.466z" fill="#fff" />
        <path
          d="M-.489 15.466 20.786-2.991v4.795L-.489 15.466z"
          fill="#E2AE57"
        />
      </g>
    </g>
  </svg>
);

export default FlagMh;
