import React from 'react';

import type { FlagProps } from '../src/types';

const FlagRw = ({ className, testID }: FlagProps) => (
  <svg
    width={20}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testID}
    className={className}
    aria-hidden="true"
  >
    <mask
      id="prefix__a"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={15}
    >
      <path fill="#fff" d="M0 0h20v15H0z" />
    </mask>
    <g mask="url(#prefix__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0v15h20V0H0z"
        fill="#FECA00"
      />
      <mask
        id="prefix__b"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={20}
        height={15}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0v15h20V0H0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__b)" fillRule="evenodd" clipRule="evenodd">
        <path d="M0 0v8.75h20V0H0z" fill="#3CA5D9" />
        <path d="M0 11.25V15h20v-3.75H0z" fill="#5A962A" />
        <path
          d="M15.051 7.416a2.582 2.582 0 0 1-.116 0l-.377 1.234-.08-1.285a2.485 2.485 0 0 1-.114-.026L13.71 8.45l.221-1.267a2.518 2.518 0 0 1-.105-.052l-.895.932.51-1.18a2.443 2.443 0 0 1-.091-.076l-1.087.702.77-1.03a2.596 2.596 0 0 1-.072-.096l-1.218.435.988-.826a2.405 2.405 0 0 1-.049-.11l-1.284.144 1.152-.577a2.357 2.357 0 0 1-.022-.12l-1.282-.152 1.255-.298.007-.122-1.21-.44 1.288-.004c.011-.04.023-.079.036-.118l-1.075-.704 1.256.29.062-.106-.882-.93 1.155.569c.027-.03.056-.06.085-.089L12.58 2.12l.992.817a1.78 1.78 0 0 1 .102-.066l-.366-1.225.775 1.023a2.25 2.25 0 0 1 .115-.042l-.071-1.276.516 1.173c.04-.005.08-.01.12-.013l.23-1.26.228 1.26.12.015.517-1.175-.071 1.28c.038.012.075.026.113.04l.777-1.025-.368 1.23c.034.02.067.042.1.065l.996-.821-.645 1.113c.028.028.055.057.081.086l1.162-.572-.888.936.06.102 1.264-.292-1.083.71c.012.036.024.074.034.112l1.298.004-1.22.443c.004.039.006.078.007.117l1.264.3-1.291.154a2.397 2.397 0 0 1-.02.114l1.16.581-1.293-.145a2.47 2.47 0 0 1-.047.106l.995.83-1.226-.436a2.38 2.38 0 0 1-.07.092l.775 1.036-1.091-.705c-.03.025-.059.05-.089.073l.513 1.186-.898-.935a2.506 2.506 0 0 1-.103.051l.222 1.271-.656-1.114a2.48 2.48 0 0 1-.112.026l-.08 1.287-.378-1.234zm-.085-.907c.876 0 1.585-.699 1.585-1.562 0-.862-.71-1.562-1.585-1.562s-1.585.7-1.585 1.562c0 .863.71 1.562 1.585 1.562zm.912-1.516a.893.893 0 1 1-1.787 0 .893.893 0 0 1 1.787 0z"
          fill="#FFDC17"
        />
      </g>
    </g>
  </svg>
);

export default FlagRw;
