import React from 'react';

import type { FlagProps } from '../src/types';

const FlagGu = ({ className, testID }: FlagProps) => (
  <svg
    width={20}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testID}
    className={className}
    aria-hidden="true"
  >
    <mask
      id="prefix__a"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={15}
    >
      <path fill="#fff" d="M0 0h20v15H0z" />
    </mask>
    <g mask="url(#prefix__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0h20v15H0V0z"
        fill="#C51918"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2h16v11H2V2z"
        fill="#4564F9"
      />
      <path
        d="M2.625 2.625h14.75v9.75H2.625v-9.75z"
        stroke="#CBDAFF"
        strokeOpacity={0.12}
        strokeWidth={1.25}
      />
      <mask
        id="prefix__b"
        maskUnits="userSpaceOnUse"
        x={2}
        y={2}
        width={16}
        height={11}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 2h16v11H2V2z"
          fill="#fff"
        />
        <path
          d="M2.625 2.625h14.75v9.75H2.625v-9.75z"
          stroke="#fff"
          strokeWidth={1.25}
        />
      </mask>
      <g mask="url(#prefix__b)">
        <path
          opacity={0.4}
          d="m10.25 3 .406-.81-.415-.208-.412.216.421.802zm0 0 .407-.81.002.001.004.002.011.006.036.019c.03.016.072.038.123.067a9.147 9.147 0 0 1 1.655 1.201c.886.814 1.918 2.124 1.918 3.855 0 1.715-1.016 3.1-1.885 3.982a10.4 10.4 0 0 1-1.753 1.422l-.035.022-.011.007-.004.002h-.001v.001L10.25 12m0-9-.422-.802h-.002l-.003.003-.011.006-.036.02-.122.068a9.584 9.584 0 0 0-1.646 1.223c-.877.816-1.914 2.127-1.914 3.823 0 1.68 1.02 3.065 1.881 3.95a10.929 10.929 0 0 0 1.745 1.446l.035.022.01.007.004.002h.001v.001l.48-.769m0 0 .466.777-.475.285-.47-.292.479-.77z"
          fill="#CCE0E5"
          stroke="#6D87FB"
          strokeWidth={1.813}
        />
        <path
          d="m10.25 3.773.43-.798-.44-.236-.434.244.444.79zm0 0 .43-.798.002.001.003.002.01.006.03.016.1.059a7.307 7.307 0 0 1 1.311 1.02c.702.692 1.52 1.807 1.52 3.276 0 1.454-.804 2.627-1.488 3.374a8.396 8.396 0 0 1-1.384 1.203l-.03.02-.009.005-.003.003h-.001c-.001 0-.001.001-.491-.761m0-7.426a127.9 127.9 0 0 0-.445-.79l-.002.002-.003.002-.01.005-.03.017a6.89 6.89 0 0 0-.425.278 7.667 7.667 0 0 0-.975.82c-.694.692-1.516 1.806-1.516 3.248 0 1.426.808 2.6 1.485 3.346a8.803 8.803 0 0 0 1.375 1.222l.028.02.01.006.003.002.001.001.504-.753m0 0 .49.762-.5.32-.493-.329.503-.753z"
          fill="#CCE0E5"
        />
        <path
          d="m10.25 3.773.43-.798-.44-.236-.434.244.444.79zm0 0 .43-.798.002.001.003.002.01.006.03.016.1.059a7.307 7.307 0 0 1 1.311 1.02c.702.692 1.52 1.807 1.52 3.276 0 1.454-.804 2.627-1.488 3.374a8.396 8.396 0 0 1-1.384 1.203l-.03.02-.009.005-.003.003h-.001c-.001 0-.001.001-.491-.761m0-7.426a127.9 127.9 0 0 0-.445-.79l-.002.002-.003.002-.01.005-.03.017a6.89 6.89 0 0 0-.425.278 7.667 7.667 0 0 0-.975.82c-.694.692-1.516 1.806-1.516 3.248 0 1.426.808 2.6 1.485 3.346a8.803 8.803 0 0 0 1.375 1.222l.028.02.01.006.003.002.001.001.504-.753m0 0 .49.762-.5.32-.493-.329.503-.753z"
          stroke="#C1C9F1"
          strokeOpacity={0.748}
          strokeWidth={1.813}
        />
        <path
          d="m10.25 3.773.43-.798-.44-.236-.434.244.444.79zm0 0 .43-.798.002.001.003.002.01.006.03.016.1.059a7.307 7.307 0 0 1 1.311 1.02c.702.692 1.52 1.807 1.52 3.276 0 1.454-.804 2.627-1.488 3.374a8.396 8.396 0 0 1-1.384 1.203l-.03.02-.009.005-.003.003h-.001c-.001 0-.001.001-.491-.761m0-7.426a127.9 127.9 0 0 0-.445-.79l-.002.002-.003.002-.01.005-.03.017a6.89 6.89 0 0 0-.425.278 7.667 7.667 0 0 0-.975.82c-.694.692-1.516 1.806-1.516 3.248 0 1.426.808 2.6 1.485 3.346a8.803 8.803 0 0 0 1.375 1.222l.028.02.01.006.003.002.001.001.504-.753m0 0 .49.762-.5.32-.493-.329.503-.753z"
          stroke="#C51918"
          strokeWidth={1.813}
        />
        <mask
          id="prefix__c"
          maskUnits="userSpaceOnUse"
          x={7}
          y={3}
          width={6}
          height={9}
        >
          <path
            d="M10.832 9.503c-.2.218-.4.404-.575.552a6.903 6.903 0 0 1-.586-.572c-.573-.631-1.015-1.38-1.015-2.128 0-.734.428-1.41.984-1.965.216-.216.434-.395.618-.533.18.132.394.305.606.513.548.54.98 1.216.98 1.985 0 .783-.446 1.532-1.012 2.148z"
            fill="#fff"
          />
          <path
            d="M10.832 9.503c-.2.218-.4.404-.575.552a6.903 6.903 0 0 1-.586-.572c-.573-.631-1.015-1.38-1.015-2.128 0-.734.428-1.41.984-1.965.216-.216.434-.395.618-.533.18.132.394.305.606.513.548.54.98 1.216.98 1.985 0 .783-.446 1.532-1.012 2.148z"
            stroke="#fff"
            strokeWidth={1.813}
          />
        </mask>
        <g mask="url(#prefix__c)">
          <path fill="#91CBCB" d="M8.083 8.75h4.667v.938H8.083z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.501 9.499s3.309 0 3.613-.113c.304-.114-.571 2.192-1.689 2.192s-1.924-2.08-1.924-2.08z"
            fill="#FDF9A1"
          />
          <path
            d="m11.621 9.222-.594.457c-.937-1.217-1.33-2.477-1.162-3.762l.743.097c-.14 1.077.192 2.14 1.013 3.208z"
            fill="#A68861"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m10.246 6.023-.567.664c-.021.025-.062.005-.054-.027l.195-.829h-.887c-.034 0-.044-.046-.013-.06l.758-.332-.538-.709c-.019-.025.007-.06.036-.048l.847.33.193-.777c.008-.032.053-.032.06 0l.192.777.84-.327c.03-.011.055.024.035.05l-.581.704.801.332c.032.013.022.06-.012.06h-.79l.12.848c.005.031-.034.049-.054.025l-.581-.681zM9.75 8.125h3v.656h-3v-.656z"
            fill="#239E46"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.295 7.745h-.003a.137.137 0 0 1-.063.08.24.24 0 0 1-.131.033.293.293 0 0 1-.117-.024.247.247 0 0 1-.092-.067.324.324 0 0 1-.06-.112.497.497 0 0 1-.021-.153c0-.057.007-.108.021-.152a.309.309 0 0 1 .06-.111.258.258 0 0 1 .094-.068.306.306 0 0 1 .122-.024.28.28 0 0 1 .157.041.276.276 0 0 1 .097.11l-.069.04a.194.194 0 0 0-.071-.086.2.2 0 0 0-.114-.031.212.212 0 0 0-.084.016.191.191 0 0 0-.108.117.27.27 0 0 0-.015.092v.111c0 .034.005.065.015.093a.187.187 0 0 0 .11.117.247.247 0 0 0 .159.007.17.17 0 0 0 .057-.028.132.132 0 0 0 .052-.113v-.068h-.154v-.073h.236v.354h-.078v-.1zm.522-.586v.425c0 .066.012.115.037.15.025.033.068.05.13.05s.105-.017.13-.05a.251.251 0 0 0 .037-.15v-.425h.084v.405c0 .05-.005.093-.013.13a.226.226 0 0 1-.044.092.179.179 0 0 1-.079.054.347.347 0 0 1-.118.018.339.339 0 0 1-.117-.018.172.172 0 0 1-.077-.054.224.224 0 0 1-.041-.092.594.594 0 0 1-.013-.13V7.16h.084zm1.223.687-.07-.203h-.282l-.07.203h-.085l.244-.687h.107l.244.687h-.088zm-.209-.61h-.005l-.118.334h.241l-.118-.335zm1.157.031h-.005l-.059.115-.167.3-.167-.3-.059-.115h-.005v.579h-.082v-.687h.112l.2.37h.005l.201-.37h.108v.687h-.082v-.579z"
            fill="#C51918"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default FlagGu;
