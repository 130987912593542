import React from 'react';

import type { FlagProps } from '../src/types';

const FlagEg = ({ className, testID }: FlagProps) => (
  <svg
    width={20}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testID}
    className={className}
    aria-hidden="true"
  >
    <mask
      id="prefix__a"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={15}
    >
      <path fill="#fff" d="M0 0h20v15H0z" />
    </mask>
    <g mask="url(#prefix__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0v15h20V0H0z"
        fill="#F7FCFF"
      />
      <mask
        id="prefix__b"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={20}
        height={15}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0v15h20V0H0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__b)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0v5h20V0H0z"
          fill="#BF2714"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 10v5h20v-5H0z"
          fill="#272727"
        />
        <path
          d="m7.663 6.388.624.028-.157 3.419-.624-.029.157-3.418zm.652.212.624.035-.157 2.772-.624-.035.157-2.772z"
          fill="#C09302"
        />
        <path
          d="m8.968 6.807.623.052-.157 1.91-.623-.052.157-1.91zm3.369-.419-.624.028.157 3.419.624-.029-.157-3.418zm-.652.212-.624.035.157 2.772.624-.035-.157-2.772z"
          fill="#C09302"
        />
        <path
          d="m11.032 6.807-.623.052.157 1.91.623-.052-.157-1.91zM9.983 9.58l-.609-.14.258-1.126.61.14-.259 1.125z"
          fill="#C09302"
        />
        <path
          d="m10.45 9.58.609-.14-.258-1.126-.61.14.259 1.125z"
          fill="#C09302"
        />
        <path
          d="M10.295 9.569H9.67V8.464h.625v1.105zM9.147 6.82l.625.016-.037 1.488-.625-.015.037-1.488zm1.087 0 .624.016-.036 1.488-.625-.015.037-1.488z"
          fill="#C09302"
        />
        <path
          d="m8.336 9.952.163-.604c.663.179 1.172.267 1.52.267.346 0 .855-.088 1.519-.267l.162.604c-.711.191-1.27.288-1.682.288-.412 0-.97-.097-1.682-.288zm-.66-3.562c-.041-.414.2-.693.61-.658.135.012.286.052.487.12l.116.04c.277.1.353.125.368.128-.061-.014.12-.34.16-.396.006.035-.015.03-.065.02l-.564-.113.402-.411a.732.732 0 0 1 .54-.216c.022 0 .043 0 .11-.005l.107-.005c.39-.013.67.098.67.499 0 .143.017.374.047.549.01.059.03.114-.027.138.002-.02.138-.075.35-.164l.026-.01c.618-.258.69-.282.889-.16.132.08.258.225.4.44l-.523.343a1.09 1.09 0 0 0-.198-.248c.078.055-.187.143-.327.201l-.025.011c-.48.2-.544.225-.681.225-.374 0-.508-.383-.545-.932-.115.38-.46.86-.743.86-.124 0-.065.019-.58-.164l-.107-.038a1.682 1.682 0 0 0-.34-.089.284.284 0 0 1 .025-.024l-.582.058z"
          fill="#C09302"
        />
      </g>
    </g>
  </svg>
);

export default FlagEg;
