const primitives = {
  // brand
  brandSleep: '#0e1438',
  brandThink: '#CBC1F6',
  brandGo: '#76fb8f',
  // gray
  gray1000: '#000000',
  gray900: '#161616',
  gray800: '#2D2D2D',
  gray700: '#444444',
  gray600: '#5A5A5A',
  gray500: '#707070',
  gray400: '#8D8D8D',
  gray300: '#A9A9A9',
  gray200: '#C6C6C6',
  gray150: '#D4D4D4',
  gray100: '#E2E2E2',
  gray50: '#F7F7F7',
  gray0: '#FFFFFF',
  // blue
  blue800: '#052C5A',
  blue700: '#074188',
  blue600: '#0A57B5',
  blue500: '#0C6DE2',
  blue400: '#3D8AE8',
  blue300: '#6DA7EE',
  blue200: '#9EC5F3',
  blue100: '#CEE2F9',
  blue50: '#E7F0FC',
  // red
  red800: '#4C0804',
  red700: '#730C06',
  red600: '#991008',
  red500: '#BF140A',
  red400: '#CC433B',
  red300: '#D9726C',
  red200: '#E5A19D',
  red100: '#EDD0CE',
  red50: '#FFF8F7',
  // yellow
  yellow800: '#9B4E00',
  yellow700: '#BA5D00',
  yellow600: '#F28900',
  yellow500: '#FFA617',
  yellow400: '#FFC759',
  yellow300: '#FFD582',
  yellow200: '#FEE5B5',
  yellow100: '#FFF2D9',
  yellow50: '#FFF8EB',
  // green
  green800: '#053525',
  green700: '#074F37',
  green600: '#0A6A4A',
  green500: '#0C845C',
  green400: '#3D9D7D',
  green300: '#6DB59D',
  green200: '#9ECEBE',
  green100: '#CEE6DE',
  green50: '#EBFFF1',
  // purple
  purple800: '#190c49',
  purple700: '#29147a',
  purple600: '#3a1caa',
  purple500: '#4a24db',
  purple400: '#7255e3',
  purple300: '#9b85eb',
  purple200: '#c3b6f3',
  purple100: '#ebe7fb',
  purple50: '#F5F2FC',
  // static
  staticSleep: '#0e1438',
  staticThink: '#CBC1F6',
  staticGo: '#76fb8f',
  staticBlack: '#000000',
  staticDimGray: '#444444',
  staticDarkGray: '#707070',
  staticLightGray: '#C6C6C6',
  staticWhite: '#FFFFFF',
} as const;

export default primitives;
