import type { BorderRadiuses } from '../types';

const borderRadius: BorderRadiuses = {
  0: 0,
  1: 4,
  2: 6,
  3: 24,
  4: 1000,
};

export default borderRadius;
