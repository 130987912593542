import type { ZIndexes } from '../types';

const zIndexes: ZIndexes = {
  sticky: 5,
  dropdown: 10,
  overlay: 15,
  dialog: 20,
};

export default zIndexes;
