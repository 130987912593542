import React from 'react';

import type { FlagProps } from '../src/types';

const FlagGs = ({ className, testID }: FlagProps) => (
  <svg
    width={20}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testID}
    className={className}
    aria-hidden="true"
  >
    <mask
      id="prefix__a"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={15}
    >
      <path fill="#fff" d="M0 0h20v15H0z" />
    </mask>
    <g mask="url(#prefix__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0h20v15H0V0z"
        fill="#2B409A"
      />
      <path
        d="M15.199 11.595a.817.817 0 0 1-.178-.075s-.101.16-.154.169c-.053.009-.327-.099-.327-.099s-.192.15-.235.14a81.61 81.61 0 0 1-.486-.102l-.24-.15s-.134.215-.14.21c-.004-.004-.403-.08-.403-.08s.12-.164.12-.168a.298.298 0 0 0-.057-.024l-.087.07-.356.034.087-.245.14-.206 1.705-1.62.841 1.516-.124.456s-.068.174-.106.174z"
        fill="#6A4C2D"
      />
      <path
        d="M13.569 11.288c.06.072.842.872.97 1.25.126.38-1.03-.104-1.03-.104s1.456-.946 2.183-.946c.727 0 2.157.946 2.157.946s-.96.675-.95.25c.01-.427.45-1.266.976-1.396"
        stroke="#FB0"
        strokeWidth={0.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m18.464 11.35-.24-.089-.211-.28-.877-.065-.8-.218-.336.225.181.237.444.163 1.839.027z"
        fill="#F7FCFF"
      />
      <g filter="url(#prefix__b)">
        <path
          d="M14.962 6.166c.008.02.187-.016.225.092.192-.285.418-.014.418-.014l.19-.225.215.204.196-.016.206.483-1.623-.03s.165-.514.173-.494z"
          fill="#656263"
        />
      </g>
      <path
        d="M16.645 8.221c-.013-.153-.149-.17-.225-.165-.075.005-.143.068-.143.068l-.25-.155s.125-.772.258-.82c.126-.09.148-.128.148-.149a.509.509 0 0 0-.047-.103l-.816-.094-.772.09s-.06.12-.052.156c.008.035.01.074.149.178.152.115.258.775.258.775s-.217.104-.23.092c-.013-.013-.078-.026-.112-.02a.204.204 0 0 0-.146.206c0 .145.112.23.112.23s.738.54.848.739c.107-.24.808-.78.892-.821.034-.02.14-.054.128-.207z"
        fill="#656263"
      />
      <path
        d="M17.381 7.501c.062-.04.47-.06.568 0 .098.06.135.355.135.355s.061.104.065.18c.004.076.013.128.013.128s.326.415.33.79c.037.256.029.938-.073 1.197-.004.324-.143.535-.143.535s.033.056.029.116a.322.322 0 0 1-.037.116l-.306.132-.34-.056-.384-1.122.09-1.896.025-.096-.041-.255s.008-.084.07-.124z"
        fill="#000"
      />
      <path
        d="M15.164 8.496s.413 0 .458.131c.078-.174.435-.163.435-.163l.648-.022.011 1.528a1.647 1.647 0 0 1-1.05 1.19c-.577-.182-.942-.659-1.06-1.201l-.068-1.452.626-.01z"
        fill="#F7FCFF"
      />
      <path
        d="m14.76 8.502.912 2.372.823-2.424c-.255.016-.803-.04-.87.188-.106-.203-.683-.121-.864-.136z"
        fill="#006B00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.817 8.674s.083.216 0 .323c-.083.107.007.195.057.195s.222.17.082.17c-.139 0-.217-.053-.217.096 0 .15.306 1.08.262.878-.045-.203-.088-.155-.088-.33 0-.176.043-.371.043-.371s.073.055.021.255c-.052.2-.29.49-.35.502-.06.011-.152.104-.164-.067-.012-.172-.061-.253-.022-.286.04-.034.092-.153.11-.06.016.095-.058.185-.046.219.012.034.019.11.07.046.052-.064.082-.17.12-.072.039.098.04.02-.04-.167s-.147-.29-.138-.455c.01-.165-.003-.226.01-.28.013-.052-.143-.31-.104-.383.04-.074.055-.314.104-.27.048.043.073.1.048.165-.025.064-.05.128-.025.172.025.043.111.168.128.137.017-.032.075-.001.075-.18 0-.178-.064-.241-.024-.284.041-.042.088.047.088.047z"
        fill="#FFD100"
      />
      <path
        d="m16.5 8.45.123-.003-.22.272.264.3-.518.638.489.574a1.488 1.488 0 0 1-.2.352l-.28-.303.51-.62-.42-.463.252-.747zm-1.887.058.246.26-.26.317.541.575-.448.586c.054.128.131.271.214.377l.266-.329-.547-.594.401-.498-.28-.697-.133.003z"
        fill="#1E5AA6"
      />
      <path
        d="m15.354 10.048-.193.252.69.78c.122-.06.217-.128.322-.21l-.36-.407.146-.42.21.237-.657.82a1.452 1.452 0 0 1-.343-.205l.348-.432-.163-.415z"
        fill="#1E5AA6"
      />
      <path
        d="M17.74 8.032c0 .04.044 2.395.092 2.373.119-.056.146.066.146.066s.04.387-.062.439a1.503 1.503 0 0 1-.264.117l-.177-.129s-.18-.252-.22-.363a3.104 3.104 0 0 1-.074-.719c.017-.108.029-.738.02-.77-.008-.032-.04-.56-.02-.639.02-.08.147-.431.143-.431 0 0 .417.016.417.056z"
        fill="url(#prefix__c)"
      />
      <path
        d="M17.332 7.629c.086 0 .151.072.172.136.004.027-.008.047-.004.1.004.051-.621.011-.625.007 0 0 .29-.275.457-.243z"
        fill="#FF7000"
      />
      <path d="M17.73 7.806a.05.05 0 1 1-.1 0 .05.05 0 0 1 .1 0z" fill="#000" />
      <path
        d="M16.138 7.443s.325.344.536.34c.038.101-.096.195-.15.24-.107-.033-.204.006-.452-.293.015-.19.07-.28.066-.287zm1.294-1.732c.03.221.549.492.698.308-.015.083.012.34-.776.336.299.277-.297.576-.466.508.31-.232-.088-.37-.31-.22.042-.128.352-.767.854-.932z"
        fill="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.906 7.26c-.059-.243-.087-.522.043-.629-.172-.116-.36.004-.36.004 0 .003-.004.008-.01.015-.04.055-.173.24.01.573-.49-.127-.284.317-.248.35.019-.07.28-.137.344.225.022.074-.11.18-.152.214-.01.007-.015.011-.012.01 0 0 .414.085.468-.463a.847.847 0 0 0 .404-.744c-.316.006-.455.354-.487.445z"
        fill="#00F"
      />
      <path
        d="M15.055 7.54s-.318.27-.498.183c-.134.08-.29-.06-.29-.06s.183.663.841.184a1.498 1.498 0 0 0-.053-.307z"
        fill="#F7FCFF"
      />
      <path
        d="M14.553 7.712c.027-.138.138-.206.226-.075.119.023.238-.452-.184-.374.12-.62-.237-.852-.237-.852s-.126.688-.069.815c.058.127-.084-.236-.54-.337-.007.52.51.763.51.763s.145.131.294.06z"
        fill="#00F"
      />
      <path
        d="M14.123 6.962c-.134.04-.46-.156-.253-.41-.678-.015-1.046-.587-.82-.478.225.109.386-.124.555 0-.207-.187.072-.337.072-.337s.578.169.681.67c-.034.097-.05.27-.057.262 0-.004-.358-.007-.178.293z"
        fill="#F7FCFF"
      />
      <path
        d="M15.422 4.672c-.064.186-.294.116-.294.116s.007.093-.016.138c.02.014.155.119.299.146.206.039.627-.068.769.09.142.157.134.284.134.284s-.184.02-.133.086c.033.042.125.073.133.117.026.138-.086.388-.046.481-.064.006-.069.071-.069.071-.003.074-.187.03-.187.03s.114-.288.122-.355c.008-.067-.08-.187-.15-.187-.068 0-.118.213-.118.213s-.034.16-.023.176l-.046-.052s-.018.065-.048.104c-.033.045-.078.064-.078.064s-.034-.098-.023-.135c.011-.037.188-.183.172-.288-.007-.046-.013-.127-.07-.153-.074-.034-.208-.01-.29-.026-.044.076-.065.493-.034.52.03.026-.076.082-.076.082l-.127.003c-.002-.056.075-.21.075-.342 0-.13.01-.27.01-.27s-.086.02-.13.104a.493.493 0 0 0-.035.22c.007.042.046.158.034.191-.011.034-.21.079-.21.079l-.008-.109s.084-.052.084-.183c0-.13.008-.318.004-.329-.002-.007-.069-.293-.157-.486-.058-.129-.148-.188-.153-.198-.011-.027-.011-.057-.011-.057s-.127.09-.276-.1c.137-.115.283-.03.283-.03s.039-.105.215-.098c.176.008.21.105.202.094 0 0 .13-.112.272-.011z"
        fill="#923F00"
      />
      <path
        d="m14.767 7.102.806.515.725-.476s.151-.082.138-.14c-.013-.06-.065-.036-.091-.026-.026.01-.76.517-.76.517l-.78-.482s-.056-.017-.067.018c-.01.036.021.056.03.074z"
        fill="#B4B6B9"
      />
      <path
        d="M17.467 10.892s-.3-.02-.254.067c.025.048.422.042.422.042l-.168-.109zm.143.116-.313.01s-.079.07-.084.086c-.005.017.052.021.052.021s.322.082.566.03c.123-.043.145-.093.059-.124-.087-.03-.278-.021-.28-.023z"
        fill="#FF7000"
      />
      <path
        d="M13.994 8.119s-.04.125 0 .187c.04.063.297.568.297.568s.174-.21.238-.216c.064-.005.035.546.035.546s-.105.096-.169.09c-.064-.005.157.217.151.404-.005.188-.29 1.12-.395 1.136-.104.017-.195-.175-.195-.342 0-.166.108-.43.102-.504-.006-.074-.52.792-.551.927a.416.416 0 0 0 .028.118s-.202.017-.157.273c-.593-.205-.884-.557-.878-.915.002-.137-.129-.46 0-.816.207-.574.712-1.228.715-1.291.006-.103.047-.148.053-.205.005-.057-.018-.347.04-.398.058-.05.36-.176.436-.17.076.005.14.057.14.057s.285 0 .302.107c.018.109-.058.148-.058.148s.04.154-.134.296z"
        fill="#8A9396"
      />
      <path
        d="M14.164 11.341c.096.066.318.106.318.106s-.545.147-1.099-.138c-.05-.222.119-.272.16-.274.099-.025.525.24.62.306z"
        fill="#2B2B2B"
      />
      <path
        d="M14.83 11.317c.597.316.66.103 1.072-.196.028-.02.164.481.74.196.814-.402-.699-1.073-.913-1.059-.214.015-1.494.744-.898 1.06z"
        fill="#00713D"
      />
      <path
        d="M14.815 4.64s-.277-.364-.277-.447m.5.447s.277-.364.277-.447"
        stroke="#CDAD56"
        strokeWidth={0.25}
        strokeLinecap="round"
      />
      <path fill="#2B409A" d="M0 0h11.25v8.75H0z" />
      <mask
        id="prefix__d"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={12}
        height={9}
      >
        <path fill="#fff" d="M0 0h11.25v8.75H0z" />
      </mask>
      <g mask="url(#prefix__d)">
        <path
          d="M-1.253 8.125 1.223 9.21l10.083-8.03 1.306-1.614-2.647-.363-4.113 3.46-3.31 2.332-3.795 3.129z"
          fill="#F7FCFF"
        />
        <path
          d="m-.914 8.886 1.261.63L12.143-.583h-1.77L-.915 8.886z"
          fill="#F50100"
        />
        <path
          d="M12.503 8.125 10.027 9.21-.056 1.18-1.362-.434l2.647-.363 4.113 3.46 3.31 2.332 3.795 3.129z"
          fill="#F7FCFF"
        />
        <path
          d="m12.418 8.67-1.261.63-5.023-4.323-1.489-.483-6.133-4.921H.283l6.13 4.804 1.628.58 4.377 3.714z"
          fill="#F50100"
        />
        <mask
          id="prefix__e"
          maskUnits="userSpaceOnUse"
          x={-1}
          y={-1}
          width={14}
          height={11}
          fill="#000"
        >
          <path fill="#fff" d="M-1-1h14v11H-1z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.24 0H4.99v3.75H0V5h4.99v3.75h1.25V5h5.01V3.75H6.24V0z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.24 0H4.99v3.75H0V5h4.99v3.75h1.25V5h5.01V3.75H6.24V0z"
          fill="#F50100"
        />
        <path
          d="M4.99 0v-.938h-.937V0h.937zm1.25 0h.938v-.938H6.24V0zM4.99 3.75v.938h.938V3.75H4.99zM0 3.75v-.938h-.938v.938H0zM0 5h-.938v.938H0V5zm4.99 0h.938v-.938H4.99V5zm0 3.75h-.937v.938h.937V8.75zm1.25 0v.938h.938V8.75H6.24zm0-3.75v-.938h-.937V5h.937zm5.01 0v.938h.938V5h-.938zm0-1.25h.938v-.938h-.938v.938zm-5.01 0h-.937v.938h.937V3.75zM4.99.937h1.25V-.938H4.99V.938zm.938 2.813V0H4.053v3.75h1.875zM0 4.688h4.99V2.813H0v1.874zM.938 5V3.75H-.938V5H.938zm4.052-.938H0v1.875h4.99V4.063zm.938 4.688V5H4.053v3.75h1.875zm.312-.938H4.99v1.875h1.25V7.813zM5.303 5v3.75h1.875V5H5.303zm5.947-.938H6.24v1.875h5.01V4.063zm-.938-.312V5h1.876V3.75h-1.876zm-4.072.938h5.01V2.813H6.24v1.874zM5.303 0v3.75h1.875V0H5.303z"
          fill="#F7FCFF"
          mask="url(#prefix__e)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="prefix__c"
        x1={17.672}
        y1={9.754}
        x2={18.136}
        y2={9.749}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D5DFFF" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <filter
        id="prefix__b"
        x={14.789}
        y={6.019}
        width={1.623}
        height={0.921}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={0.25} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default FlagGs;
