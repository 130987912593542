import type { Spacings } from '../types';

const spacings: Spacings = {
  0: 0,
  1: 2,
  2: 4,
  3: 8,
  4: 12,
  5: 16,
  6: 20,
  7: 24,
  8: 32,
  9: 40,
  10: 64,
  11: 128,
  12: 264,
};

export default spacings;
