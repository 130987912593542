import React from 'react';

import type { FlagProps } from '../src/types';

const FlagSi = ({ className, testID }: FlagProps) => (
  <svg
    width={20}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testID}
    className={className}
    aria-hidden="true"
  >
    <mask
      id="prefix__a"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={15}
    >
      <path fill="#fff" d="M0 0h20v15H0z" />
    </mask>
    <g mask="url(#prefix__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0v15h20V0H0z"
        fill="#2E42A5"
      />
      <mask
        id="prefix__b"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={20}
        height={15}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0v15h20V0H0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__b)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0v5h20V0H0z"
          fill="#F7FCFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 10v5h20v-5H0z"
          fill="#C51918"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.588 3.148s-1.316-.622-2.952-.622-2.952.622-2.952.622.147 2.496.378 3.819c.099.565.574 1.388 1.004 1.778.49.445 1.57.971 1.57.971.313-.215.897-.312 1.644-.97.748-.66.933-1.779.933-1.779l.375-3.82z"
          fill="#2E42A5"
        />
        <path
          d="m8.414 3.066.548.274-.377 3.764c-.131.796-.46 1.425-.988 1.89-.286.251-.565.44-.864.596l-.87.45a1.15 1.15 0 0 0-.167.093l-.069.047-.073-.036C4.822 9.788 4.139 9.308 3.8 9c-.463-.42-.951-1.28-1.056-1.874-.155-.89-.295-2.116-.393-3.786l.492-.258c.098 1.658.215 2.98.368 3.854.09.517.54 1.31.951 1.682.304.275.793.58 1.465.912.04-.023.085-.046.136-.07.001-.002.459-.205.618-.288a3.98 3.98 0 0 0 .805-.556c.467-.412.76-.967.877-1.673l.35-3.877z"
          fill="#C51918"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.464 6.89s.119.365.34.775c.323.085.521.08.584.01.181-.202.825-.289.946-.116.074.107.335.181.62.083l-.032.006c.05-.018.165-.064.237-.092l.058-.023a.937.937 0 0 1 .267-.068c.1-.008.197.011.3.06.224.107.502.157.69.124.232-.402.397-.759.397-.759L6.773 5.837l-.436.473-.724-1.032-.607 1.032-.465-.473L3.464 6.89zm.634 1.244a3.42 3.42 0 0 1-.143-.21c.28.051.471.024.577-.094.05-.056.199-.11.368-.134a.978.978 0 0 1 .212-.007.286.286 0 0 1 .063.011c.005.002.004.002 0-.005l-.001-.001c.129.185.48.284.811.163l-.031.006c.09-.028.25-.091.309-.114l.02-.008a.77.77 0 0 1 .214-.056.392.392 0 0 1 .206.042c.199.095.43.151.635.153a7.41 7.41 0 0 1-.135.208c-.158.037-.404-.011-.602-.12a.47.47 0 0 0-.273-.062.751.751 0 0 0-.239.07 10.445 10.445 0 0 1-.257.115l.036-.008c-.248.096-.465.026-.527-.077-.11-.182-.69-.093-.852.116-.044.056-.175.066-.391.012zm.216.256.037.037c.564.55 1.262.82 1.262.82s.831-.252 1.303-.781c.04-.045.08-.094.12-.146a1.262 1.262 0 0 1-.525-.154.284.284 0 0 0-.167-.04.586.586 0 0 0-.195.061 4 4 0 0 1-.281.117l.036-.007c-.292.122-.602.025-.723-.157a.202.202 0 0 0-.049-.01.723.723 0 0 0-.18.007c-.143.022-.268.075-.31.128-.07.09-.179.13-.328.125z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.467 3.679h-.44l.341-.32-.34-.337h.439l.185-.327.187.327h.438l-.323.317.287.34h-.402l-.187.346-.185-.346zm2.071 0h-.44l.341-.32-.34-.337h.439l.185-.327.187.327h.438l-.323.317.287.34H6.91l-.187.346-.185-.346zM5.375 4.73h-.44l.341-.32-.34-.337h.439l.185-.328.187.328h.438l-.323.317.287.34h-.402l-.187.345-.185-.345z"
          fill="#FECA00"
        />
      </g>
    </g>
  </svg>
);

export default FlagSi;
