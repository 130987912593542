import React from 'react';

import type { FlagProps } from '../src/types';

const FlagGq = ({ className, testID }: FlagProps) => (
  <svg
    width={20}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testID}
    className={className}
    aria-hidden="true"
  >
    <mask
      id="prefix__a"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={15}
    >
      <path fill="#fff" d="M0 0h20v15H0z" />
    </mask>
    <g mask="url(#prefix__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0v15.36h20.48V0H0z"
        fill="#F7FCFF"
      />
      <mask
        id="prefix__b"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={21}
        height={16}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0v15.36h20.48V0H0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__b)" fillRule="evenodd" clipRule="evenodd">
        <path d="M0 0v5.12h20.48V0H0z" fill="#009D00" />
        <path d="M0 5.12v5.12h20.48V5.12H0z" fill="#fff" />
        <path d="M0 10.24v5.12h20.48v-5.12H0z" fill="#F80000" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0v15.36l5.12-7.583L0 0z"
        fill="#0075D5"
      />
      <path
        d="m9.326 5.689-.01.002-.008-.007-.126-.117-.016.172-.001.01-.01.005-.15.084.159.068.01.004.001.01.034.17.113-.13.007-.008.01.002.171.02-.087-.148-.006-.01.005-.009.072-.156-.168.038zm.64 0-.01.002-.008-.007-.126-.117-.016.172-.001.01-.01.005-.15.084.159.068.01.004.001.01.034.17.113-.13.007-.008.01.002.171.02-.087-.148-.006-.01.005-.009.072-.156-.168.038zm.64 0-.01.002-.008-.007-.127-.117-.015.172-.001.01-.01.005-.15.084.159.068.01.004.001.01.034.17.113-.13.007-.008.01.002.171.02-.087-.148-.006-.01.005-.009.072-.156-.168.038zm.64 0-.01.002-.008-.007-.127-.117-.015.172-.001.01-.01.005-.15.084.159.068.01.004.001.01.034.17.113-.13.007-.008.01.002.171.02-.087-.148-.006-.01.005-.009.072-.156-.168.038zm.64 0-.01.002-.008-.007-.127-.117-.015.172-.001.01-.01.005-.15.084.159.068.01.004.001.01.034.17.113-.13.007-.008.01.002.171.02-.087-.148-.006-.01.005-.009.072-.156-.168.038zm.64 0-.01.002-.008-.007-.126-.117-.016.172-.001.01-.01.005-.15.084.159.068.01.004.001.01.034.17.113-.13.007-.008.01.002.171.02-.087-.148-.006-.01.005-.009.072-.156-.168.038z"
        fill="#FFD500"
        stroke="#000"
        strokeWidth={0.04}
      />
      <path
        d="M9.64 6.44h2.48v1.88a1.24 1.24 0 0 1-2.48 0V6.44z"
        stroke="#373737"
        strokeWidth={0.08}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.705 7.654s-.13 1.514-.258 1.606c-.129.092.618.137.689.069.071-.07-.326-1.303-.19-1.675.137-.372.357-.402.357-.402s-.277-.058-.356.053c-.08.112-.316-.053-.316-.053l.074.402z"
        fill="#6F3E1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.677 7.569h-.1s-.067.263-.221.131c-.154-.131-.372-.085-.372-.085s.02-.157.256-.22c.237-.062.337-.57.337-.57s.335-.125.54.13c.203.256.49.147.49.147s.21.12.105.293c-.105.174-.284.3-.44.174-.156-.127-.595 0-.595 0z"
        fill="#009D29"
      />
    </g>
  </svg>
);

export default FlagGq;
