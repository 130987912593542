import React from 'react';

import type { FlagProps } from '../src/types';

const FlagAs = ({ className, testID }: FlagProps) => (
  <svg
    width={20}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testID}
    className={className}
    aria-hidden="true"
  >
    <mask
      id="prefix__a"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={15}
    >
      <path fill="#fff" d="M0 0h20v15H0z" />
    </mask>
    <g mask="url(#prefix__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0h20v15H0V0z"
        fill="#F7FCFF"
      />
      <path
        d="M0-.625h-.625v7.692L-1.78 7.5l1.155.433v7.692H20l.22-1.21L1.78 7.5 20.22.585 20-.625H0z"
        fill="#3547A0"
        stroke="#E31D1C"
        strokeWidth={1.25}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.556 3.767s-5.04 1.309-5.04 1.51c0 .201-.453.84-.36 1.006.093.166.334.387.532.277.198-.11.668-.554.51-.277-.157.277.85.598.425 1.21 0 0 .51-.294.263 1.377l1.213.346s1.356-.355.737-1.372h.718s-.297-1.318.143-1.561c.44-.244-.449-.429-.449-.429 1.878-1.069 1.308-2.087 1.308-2.087z"
        fill="#C44800"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m16.722 9.357-3.042-.515-.399-.533-1.727.348 1.516.859.51-.275 3.142.502v-.386z"
        fill="#FFC221"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.189 9.824v-.301l-3.41-.208s-.207-.19-.714-.19-.565.398-.565.398.29.58.855.58c.564 0 1.441.135 1.679.414.237.279 1.222-.143 1.222-.143s-.421-.724-.96-.497c-.54.226-1.518-.11-1.518-.232 0-.122 3.411.18 3.411.18zm-5.761-2.44s-.33.709-.21.977c.528.551 1.488-.328 1.488-.328l-1.278-.65z"
        fill="#FFC221"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.642 7.722c.351-.18.53-.515.53-.95 0-.336-.08-.602-.256-.771v-.229l-.31-.003c-2.774-.025-4.336.25-4.789.936-.458.695-.418 1.288.205 1.576l.276-.007c1.347-.702 2.717-.877 4.126-.527l.218-.025zm-4.564-.845c.366-.554 1.874-.82 4.525-.795v.079c.171.082.257.285.257.61s-.12.55-.36.673c-1.484-.369-2.933-.184-4.347.553-.415-.192-.44-.565-.075-1.12z"
        fill="#979797"
      />
      <path
        d="M16.603 6.082c-2.651-.024-4.16.24-4.525.795-.365.555-.34.928.075 1.12 1.414-.737 2.863-.922 4.346-.553.24-.123.36-.348.36-.673 0-.325-.085-.528-.256-.61v-.08z"
        fill="#F7FCFF"
      />
    </g>
  </svg>
);

export default FlagAs;
