import React from 'react';

import type { FlagProps } from '../src/types';

const FlagCv = ({ className, testID }: FlagProps) => (
  <svg
    width={20}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testID}
    className={className}
    aria-hidden="true"
  >
    <mask
      id="prefix__a"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={15}
    >
      <path fill="#fff" d="M0 0h20v15H0z" />
    </mask>
    <g mask="url(#prefix__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0v15h20V0H0z"
        fill="#4141DB"
      />
      <mask
        id="prefix__b"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={20}
        height={15}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0v15h20V0H0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__b)">
        <path
          d="M0 8.125h-.625v2.5h21.25v-2.5H0z"
          fill="#F90000"
          stroke="#F7FCFF"
          strokeWidth={1.25}
        />
        <g filter="url(#prefix__c)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m5.119 6.32.53-.37.597.43-.009.032.027-.019.027.02-.008-.033.617-.43.596.43-.009.032.027-.019.027.02-.008-.033.617-.43.514.37h-.291l.588.521-.224.821.663-.461.514.37h-.291l.588.52-.199.73h-.39l.589.52-.199.73h-.39l.589.52-.199.73h-.311l-.302-.67-.258.67h-.768l.588.52-.199.73h-.311l-.302-.67-.258.67h-.69l-.302-.67-.258.67h-.69l-.302-.67-.258.67h-.373l-.177-.73.515-.52h-.655l-.302-.67-.258.67h-.373l-.177-.73.515-.52h-.338l-.177-.73.515-.52h-.338l-.177-.73.515-.52H3.87l.53-.37.642.461-.2-.821.515-.52H5.12zm-.107 0 .199-.729-.588-.52h.768l.258-.67.302.67h.69L6.9 4.4l.302.67h.689l.258-.67.302.67h.655l-.515.52.177.73h.373l.258-.67.302.67h.655l-.515.52.177.73h.373l.258-.67.302.67h.655l-.515.52.177.73h.338l-.515.52.177.73h.338l-.515.52.2.821-.641-.461-.531.37h.237l-.515.52.2.821L9.4 12.2l-.531.37h.237l-.515.52.2.821-.641-.461-.617.43.008.031-.027-.019-.027.02.01-.033-.597-.43-.617.43.008.032-.027-.019-.027.02.01-.033-.597-.43-.663.462.224-.821-.588-.52h.29L4.4 12.2l-.663.461.224-.821-.588-.52h.29l-.513-.37-.663.461.224-.821-.588-.52h.39l.198-.73-.588-.52h.39l.198-.73-.588-.52h.768l.258-.67.302.67h.311l.199-.73-.588-.52h.768l.258-.67.302.67h.311zM3.35 8.595l.315.227H3.45l-.102-.227zm-.73.227.372-.26-.1.26H2.62zm0 1.25h.272l.1-.26-.372.26zm.73-.227.102.227h.213l-.315-.227zm2.742 3.247.173-.174.197.174-.195.718-.175-.718zm1.425.718.195-.718-.197-.174-.173.174.175.718zm2.875-3.738h-.272l.372-.26-.1.26zm.56 0-.102-.227.315.227h-.213zm.213-1.25-.315-.227.102.227h.213zm-.673-.26-.372.26h.272l.1-.26zm-2.78-2.97-.195.718-.175-.718.173-.174.197.174zm-1.447-.174-.173.174.175.718.195-.718-.197-.174z"
            fill="#FFDE00"
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="prefix__c"
        x={-0.877}
        y={1.401}
        width={15.483}
        height={15.512}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={1.5} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default FlagCv;
